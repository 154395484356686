.selected-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 32px;
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    & > * {
      font-size: 32px;
    }
  }
}
