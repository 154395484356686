@import 'src/theme.scss';

.login {
  &__backdrop {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &__form {
    display: flex !important;
    flex-direction: column;
    gap: 8px !important;
  }
  &__form-wrapper {
    max-width: 300px;
    border: 1px solid rgba(255,255,255,0.3);
    padding: 16px 8px;
    border-radius: 4px;
    position: relative;
  }
  &__loading {
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    & > .anticon {
      font-size: 32px;
    }
  }
}

.ant-form-item {
  margin-bottom: 0 !important;
}