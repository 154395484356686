.menu-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  margin: 32px 0 16px;
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 32px 16px;
  gap: 16px;
  & > * {
    width: calc(100% - 32px);
  }
}
