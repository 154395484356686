.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.hide-arrows::-webkit-inner-spin-button,
.hide-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-arrows {
  -moz-appearance: textfield;
  appearance: textfield;
}

.table-select {
  & .ant-select-selector {
    border: 0 !important;
  }
  & .ant-select-open {
    border: 1px solid #434343;
  }
}

.table-select:hover {
  border: 1px solid #434343;
}
