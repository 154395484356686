.node-user {
  &__delete-popconfirm {
    & .ant-popover-message-title {
      max-width: 320px;
    }
  }
}

.ant-popover-message-title {
  max-width: 320px;
}
