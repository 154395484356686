.license-active-nodes {
  position: relative;

  &__no-hover {
    display: block;
  }

  &__hover {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    padding: 8px;
    width: 160%;
    background-color: black;
  }

  &:hover {
    > * {
      &.license-active-nodes__no-hover {
        display: none;
      }

      &.license-active-nodes__hover {
        display: block;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
    background-color: black;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    &:last-child {
      margin-bottom: 0;
    }

    &--icon {
      margin-right: 8px;
    }

    &--name {
      font-weight: normal;
      font-size: 14px;
    }
  }
}
