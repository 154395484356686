.node-licenses {
  margin-top: 32px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
