.node-users {
  display: flex;
  flex-direction: column;
  gap: 32px;
  &__section {
  }
  &__description {
    display: flex;
    gap: 16px;
  }
}
