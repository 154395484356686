@import "../../../../theme.scss";

.value-with-label {
  display: flex;
  align-items: center;
  gap: 4px;
  &__label {
    color: $disabled-light;
  }
}
