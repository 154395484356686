$primary-color: #62E2F0;
$success-color: #BF5AE5;
$info-color: #5D4FFF;
$highlight-color: #329AF5;
$body-background: #171924;
$font-size-base: 16px;
$font-size-sm: 14px;

$primary: #62e2f0;
$accent-a: #bf5ae5;
$accent-b: #5d4fff;
$accent-c: #329af5;
$red: #ff002e;

$primary-contrast: #248295;
$accent-a-contrast: #502b96;
$accent-b-contrast: #2f3092;
$accent-c-contrast: #306292;

$body-background: #171924;
$body-background-90: #2e303a;
$body-background-10: #e8e8e9;
$elevation-l1: #20222d;
$elevation-l2: #252731;
$elevation-l3: #2c2e38;
$elevation-l4: #373943;
$elevation-l5: #41424b;

$high-contrast: #ffffff;
$medium-contrast: #cecece;
$low-contrast: #a3a2a6;

$disabled: #41424b;
$disabled-light: #a3a2a6;

$orange: rgba(255, 159, 24, 1);
$blue: rgba(0, 166, 255, 1);
$green: rgba(104, 255, 79, 1);

$sm: 768px;
$md: 1024px;
$lg: 1280px;
$xl: 1440px;