@import "~antd/dist/antd.dark.css";
@import "./theme.scss";

.ant-layout-sider-children {
  overflow-y: scroll;
}
.ant-input-password {
  background-color: rgb(232, 240, 254);
  box-shadow: inset 2px 2px 4px rgb(0 0 0 / 25%);
  & input {
    box-shadow: unset;
    background-color: transparent;
    color: black;
  }
  & .ant-input-password-icon {
    color: rgba(0, 0, 0, 0.4) !important;
  }
}

.ant-layout-header {
  padding: 0;
  height: unset;
}

.ant-layout-header .ant-menu {
  line-height: 48px;
  border-bottom: 1px solid black;
}
