.node-tree {
  display: flex;
  padding: 0;
  width: 100%;
  height: 100%;
  flex-direction: row !important;
  overflow-y: scroll;
  & > aside {
    height: 100%;
  }
}
