@import "src/theme.scss";

.left-header {
  display: flex;
  width: 100%;
  gap: 40px;
  &__logo > img {
    height: 32px;
  }
  &__menu {
    margin: -8px 0;
    width: 100%;
    &--current {
      display: flex;
      margin-left: 16px;
    }
    & .ant-menu-title-content {
      color: white;
    }
    & .ant-menu-item-icon {
      transform: translateX(5px);
      color: white;
    }
    & .ant-menu-horizontal *:after {
      transition: background 0.3s !important;
    }
    & .ant-menu-horizontal a {
      color: white !important;
      font-size: 12px;
    }
    & .ant-menu-item-active {
      position: relative;

      &:after {
        border-bottom: 1px solid white !important;
        position: absolute;
        right: calc(50% - 5px) !important;
        bottom: 0;
        left: calc(50% - 5px) !important;
        width: 10px !important;
      }
      &:before {
        position: absolute;
        content: "";
        width: 40px;
        height: 40px;
        left: calc(50% - 20px) !important;
        right: calc(50% - 20px) !important;
        bottom: -20px;
        background: radial-gradient(
          circle,
          rgba(255, 255, 255, 0.35) 10%,
          rgba(255, 255, 255, 0) 59%
        );
      }
    }
    & .ant-menu-item-selected {
      border-left: 0;
      background-color: rgba(98, 226, 240, 0.1);
      overflow: hidden;
      position: relative;
      &:before {
        position: absolute;
        content: "";
        width: 40px;
        height: 40px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        bottom: -20px;
        background: radial-gradient(
          circle,
          rgba(255, 255, 255, 0.35) 10%,
          rgba(255, 255, 255, 0) 59%
        );
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.03);
      }
      &:after {
        border-bottom: 1px solid white !important;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 10px !important;
      }
    }
  }
}
