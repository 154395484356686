@import "src/theme.scss";

.primary-header {
  background-color: $body-background;
  height: 48px;
  border-bottom: 1px solid black;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > * {
    flex-grow: 1;
    flex-basis: 0;
    line-height: 32px;
  }
  &__right {
    display: flex;
    justify-content: flex-end;
  }
  &__left {
    display: flex;
    flex-grow: 3;
    margin: 0;
    padding: 0;
  }
  &__center {
    display: flex;
    justify-content: center;
  }
  & .ant-menu {
    background-color: $body-background;
  }
}
