@import "src/theme.scss";

.styled-primary-button {
  border: 1px solid $low-contrast;
  color: $low-contrast;
  border-radius: 3px;
  font-size: 14px;
  &:not(.ant-btn-icon-only) > .anticon {
    margin-right: 4px;
  }
  &:hover {
    border: 1px solid $high-contrast;
    color: $high-contrast;
  }
  &:active {
    border: 1px solid $primary;
    color: $primary;
  }
  &__no-border {
    border: 0 !important;
  }
  &__gradient {
    &:hover {
      border: 0 !important;
      background: linear-gradient(
        90deg,
        #bf5ae6 0%,
        #5d4fff 33.71%,
        #329af5 72.26%,
        #62e2f0 100%
      ) !important;
    }
    background: linear-gradient(
      90deg,
      rgba(191, 90, 230, 0.2) 0%,
      rgba(93, 79, 255, 0.2) 33.71%,
      rgba(50, 154, 245, 0.2) 72.26%,
      rgba(98, 226, 240, 0.2) 100%
    ) !important;
    border: 0 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35), 0px 4px 16px rgba(0, 0, 0, 0.35),
      inset 1px 1px 1px rgba(191, 90, 229, 0.9), inset 1px 1px 8px rgba(191, 90, 229, 0.6) !important;
    text-shadow: 1px 1px 1px #171924 !important;
    color: white !important;
  }
  &__disabled {
    background: unset !important;
    box-shadow: unset !important;
    color: $low-contrast !important;
    &:hover {
      background: unset !important;
      box-shadow: unset !important;
      border: 1px solid $low-contrast !important;
    }
  }
}
