@import "src/theme.scss";

.ant-layout.main-layout {
  height: 100vh;
  overflow: hidden;
}

.breadcrumbs {
  padding: 8px 0;
}

.sidebar {
  background-color: $elevation-l1;
  border-right: 1px solid black !important;
  position: "sticky";
  top: 0;
  left: 0;
  border: 0;
}

.objectives {
  padding: 16px 32px;
  background-color: #36393f;
  border-bottom: 1px solid rgba(255, 255, 255, 0.13);

  &__message {
    background-color: rgba(255, 255, 255, 0.07);
    border: 1px solid rgba(255, 255, 255, 0.13);
    & button.ant-alert-close-icon {
      position: absolute;
      top: 13px;
      right: 6px;
    }
  }
}

.main-content {
  height: calc(100vh - 48px - 88px);
  position: relative;
  display: flex;
  flex-direction: row;
}

.secondary-header {
  background-color: $elevation-l1 !important;
}

.content-area {
  width: 100%;
  background-color: $body-background;
  height: calc(100vh - 56px - 80px);
  overflow: hidden;
  &__panels {
    display: flex;
    width: 200%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-50%);
    &--right-visible {
      transform: translateX(0);
    }
  }
  &__panel {
    width: 50%;
  }
}
