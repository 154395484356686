.table-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  & > * {
    display: flex;
    justify-content: center;
    gap: 32px;
  }
}
